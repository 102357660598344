////
/// @group wk.css.components.labels
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.9.0
/// @access public
////

@import 'wk-lib/utilities/exports';

@include wk-exports('labels') {

    %scss-deps-labels {
        utils: wk-utils-dependencies(
            function wk-primary-color,
            mixin wk-label,
            variable wk-state-danger-text-color,
            variable wk-state-success-text-color,
            variable wk-state-info-text-color
        );
    }

    .wk-label {
        @include wk-label();
    }

    .wk-label-danger {
        @include wk-label(lighten($wk-state-danger-text-color, 20%), wk-primary-color(wkWhite));
    }

    .wk-label-info {
        @include wk-label(lighten($wk-state-info-text-color, 20%), wk-primary-color(wkWhite));
    }

    .wk-label-success {
        @include wk-label(lighten($wk-state-success-text-color, 20%), wk-primary-color(wkWhite));
    }
}
