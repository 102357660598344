////
/// @group wk.css.lib.mixins.page
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.8.0
/// @access public

/// Give a block container horizontal padding only when smaller than the maximum page width
///
/// @param {Bool} $mobile-transition [true]
///    Use a CSS padding transition on mobile.
/// @require {Mixin} container - from susy
/// @require {Mixin} breakpoint - from breakpoint of course
/// @require {Variable} $wk-page-content-padding
/// @require {Variable} $wk-desktop-max-width
///
/// @example scss - Usage
///   .my-div {
///     @include wk-page-content;
///   }
///
/// @example css - Output
///   .my-div {
///       max-width: 75rem;
///       margin-left: auto;
///       margin-right: auto;
///       padding-left: 1.2rem;
///       padding-right: 1.2rem;
///       transition: padding .3s linear;
///   }
///
///   .my-div:after {
///       content: " ";
///       display: block;
///       clear: both;
///   }
///
///   @media (min-width: 77.4rem) {
///       .my-div {
///           padding-left: 0;
///           padding-right: 0;
///           transition: padding .3s linear;
///       }
///   }

@mixin wk-page-content($mobile-transition: true) {
    @include container;

    padding-left: $wk-page-content-padding;
    padding-right: $wk-page-content-padding;

    @if $mobile-transition {
        transition: padding .3s linear;
    }

    @include breakpoint($wk-desktop-max-width + (2 * $wk-page-content-padding)) {
        padding-left: 0;
        padding-right: 0;
        transition: padding .3s linear;
    }
}
