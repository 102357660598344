////
/// @group wk.css.components.content-filter
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.9.0
/// @access public
////

@import 'wk-lib/utilities/utilities';

/// Maximum width of facet count
///
/// @type Unit
$wk-badge-max-width: wk-rem(60px) !default;

///FIXME: this colors are outdated
/// List anchor background color
///
/// @type Color
$wk-content-filter-link-background: #e8e9f5 !default;

///FIXME: this colors are outdated
/// List anchor text color
///
/// @type Color
$wk-content-filter-link-color: #7a93ad !default;

/// List active anchor background color
///
/// @type Color
$wk-content-filter-active-link-background: $wk-base-content-filter-active-background !default;

/// List active anchor background color
///
/// @type Color
$wk-content-filter-active-link-color: wk-primary-color(wkBlack) !default;

///FIXME: this colors are outdated
/// Search filter title color
///
/// @type Color
$wk-content-filter-title-color: #dd7d86 !default;

/// Content filter breakpoint
///
/// @type Unit
///
/// 50.75rem FYI is exactly halfway between tablet and desktop
$wk-content-filter-breakpoint: 57rem !default;

/// Badge text color
///
/// @type Color
$wk-content-filter-badge-color: wk-primary-color(wkWhite) !default;

@include wk-exports('content-filter') {

    %scss-deps-content-filter {
        components: wk-component-dependencies(layout);
        utils: wk-utils-dependencies(
            function wk-primary-color,
            mixin breakpoint,
            mixin container,
            mixin last,
            mixin span,
            mixin wk-link,
            mixin wk-rem,
            mixin wk-unstyle-list,
            variable wk-base-content-filter-active-background,
            variable wk-font-scale,
            variable wk-global-columns,
            variable wk-mobile-max-breakpoint
        );
    }

    .wk-content-filter-layout {
        @include container;

        .wk-content-filter-pane {

            @include breakpoint($wk-mobile-max-breakpoint $wk-content-filter-breakpoint) {
                @include span($wk-global-columns / 3 of $wk-global-columns); // 1/3
            }

            @include breakpoint($wk-content-filter-breakpoint) {
                @include span($wk-global-columns / 4 of $wk-global-columns); // 1/4
            }
        }

        .wk-content-filter-results {

            @include breakpoint($wk-mobile-max-breakpoint $wk-content-filter-breakpoint) {
                @include span($wk-global-columns / 3 * 2 of $wk-global-columns); // 2/3
                @include last;
            }

            @include breakpoint($wk-content-filter-breakpoint) {
                @include span($wk-global-columns / 4 * 3 of $wk-global-columns); // 3/4
                @include last;
            }
        }
    }

    .wk-content-filter {
        margin-bottom: wk-rem(10px);

        a {
            font-size: map-get($wk-font-scale, smaller);
        }
    }

    .wk-content-filter-title {
        color: $wk-content-filter-title-color;
        font-size: map-get($wk-font-scale, base);
        font-weight: map-get($wk-font-weight, light);
        margin-top: 0;
        text-transform: uppercase;
    }

    .wk-content-filter-list {
        @include wk-unstyle-list(true);

        > li {
            @include wk-clearfix;

            margin-bottom: wk-rem(1px);

            > a {
                background: $wk-content-filter-link-background;
                color: $wk-content-filter-link-color;
                font-size: map-get($wk-font-scale, base);
                padding: wk-rem(5px 10px);

                //to have wk-content-filter-label first semantically, it must float left and have a max width
                .wk-content-filter-label {
                    @include wk-text-overflow;
                }

                .wk-badge {
                    @include wk-text-overflow;

                    color: $wk-content-filter-badge-color;
                    float: right;
                    margin-left: wk-rem(8px); //breaking the top-down left-to-right margin rule because this is floating right and comes first in the markup, so applying some space on this element so text doesn’t touch
                    max-width: $wk-badge-max-width;
                    top: auto;

                    @include breakpoint($wk-mobile-max-breakpoint $wk-content-filter-breakpoint) {
                        @include wk-hidden;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.active a {
                background: $wk-content-filter-active-link-background;
            }

            &:hover a,
            &.active a {
                color: $wk-content-filter-active-link-color;
            }
        }
    }

    //no flexbox for now
    // scss-lint:disable SelectorDepth
    //.flexbox .wk-content-filter-list li a {
    //    display: flex;

    //    .wk-facet {
    //        margin-right: $wk-facet-label-margin-right - $wk-badge-max-width;
    //        width: 100%;
    //    }

    //    .wk-badge-container {
    //        align-items: center;
    //        display: flex;
    //        float: none;
    //        order: 1;
    //    }
    //}
    // scss-lint:enable SelectorDepth
}
