////
/// @group wk.css.components.brand
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.3.0
/// @access public
////

@import 'wk-lib/utilities/exports';
@import 'wk-lib/utilities/mixins';


@include wk-exports('brand') {

    %scss-deps-brand {
        utils: wk-utils-dependencies(mixin wk-brand);
    }

    .wk-brand {
        @include wk-brand(194px);
    }

    .wk-brand-light {
        // scss-lint:disable ColorVariable
        @include wk-brand(194px, '#fff');
    }

    .wk-brand-small {
        @include wk-brand-small(142px);
    }

    .wk-brand-small-light {
        // scss-lint:disable ColorVariable
        @include wk-brand-small(142px, '#fff');
    }
}
