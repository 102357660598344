////
/// @group wk.css.components.nav
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.10.1
/// @access public
////

@import 'wk-lib/utilities/exports';

/// Border color for active tab
///
/// Type @Color
$wk-nav-tabs-active-border: wk-primary-color(wkGray, tint4) !default;

/// Border color for active link
///
/// Type @Color
$wk-nav-tabs-active-link: wk-primary-color(wkBlack) !default;

/// Border color for active link background
///
/// Type @Color
$wk-nav-tabs-active-background: wk-primary-color(wkWhite) !default;

/// Border color for active link color
///
/// Type @Color
$wk-nav-tabs-active-link-color: wk-primary-color(wkGray) !default;

@include wk-exports('nav') {

    %scss-deps-navs {
        utils: wk-utils-dependencies(
            mixin wk-clearfix,
            mixin wk-unstyle-list,
            function wk-rem,
            function wk-primary-color,
            variable wk-font-scale,
            variable wk-headings-font-stack
        );
    }

    .wk-nav {
        @include wk-unstyle-list;
        @include wk-clearfix;

        @include wk-target-icons {
            font-size: wk-rem(22px);
            position: relative;
            top: wk-rem(2px);
        }

        > li {
            display: block;
            float: none;

            @include breakpoint($wk-target-tablet-desktop) {
                @include wk-clearfix;
                float: left;
            }

            a {
                display: block;
                font-family: $wk-headings-font-stack;
                font-size: map-get($wk-font-scale, small);
                padding: wk-rem(12px 15px);
            }

            &.active a {
                color: $wk-nav-tabs-active-link;
            }
        }
    }

    .wk-nav-tabs {
        &.wk-nav {
            border-bottom: 1px solid $wk-nav-tabs-active-border;

            > li {
                @include breakpoint($wk-target-tablet-desktop) {
                    > a {
                        border: 1px solid transparent;
                        border-bottom: 0;
                        margin-bottom: wk-rem(-1px);
                    }

                    &.active > a {
                        background-color: $wk-nav-tabs-active-background;
                        border: 1px solid $wk-nav-tabs-active-border;
                        border-bottom: 0;
                        color: $wk-nav-tabs-active-link-color;
                    }
                }
            }
        }
    }

    .wk-nav.wk-nav-stacked {
        > li {
            display: block;
            float: none;

            > a {
                display: block;
                font-family: $wk-headings-font-stack;
                font-size: map-get($wk-font-scale, small);
                padding: wk-rem(12px 15px);
            }
        }
    }
}
