////
/// @group wk.css.lib.mixins.grid
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.10.0
/// @access public

/// Creates columns for every span size on the grid from 1 to `wk-global-columns`
///
/// @require {Mixin} last - which comes from Susy library http://susy.oddbird.net/
/// @require {Mixin} span - which comes from Susy library http://susy.oddbird.net/
/// @require {Variable} wk-global-columns
///
/// @example scss - Usage
///   .row {
///     @include wk-make-columns;
///   }
///
/// @example css - Output
///   .row div[class^="wk-col"]:last-child {
///     float: right;
///     margin-right: 0;
///   }
///   .row .wk-col-1 {
///     width: 6.77966%;
///     float: left;
///     margin-right: 1.69492%;
///   }
///   (...)
///   .row .wk-col-12 {
///     width: 23.72881%;
///     float: left;
///     margin-right: 1.69492%;
///   }
@mixin wk-make-columns() {
    div[class^="wk-col"]:last-child {
        @include last;
    }

    @for $i from 1 through $wk-global-columns {
        .wk-col-#{$i} {
            @include span($i of $wk-global-columns);
        }
    }
}
