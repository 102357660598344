////
/// @group wk.css.components.toolbelt
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.3.0
/// @access public
////

@import 'wk-lib/utilities/exports';

@include wk-exports('toolbelt') {

    %scss-deps-toolbelt {
        utils: wk-utils-dependencies(
            mixin wk-hide-text,
            mixin wk-clearfix,
            mixin wk-text-overflow,
            mixin wk-shown,
            mixin wk-hidden
        );
    }

    .wk-hide-text {
        @include wk-hide-text;
    }

    .wk-clearfix {
        @include wk-clearfix;
    }

    .wk-text-overflow {
        @include wk-text-overflow;
    }

    .wk-shown {
        @include wk-shown;
    }

    .wk-hidden {
        @include wk-hidden;
    }
}
