////
/// @group wk.css.components.breadcrumbs
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.8.0
/// @access public
////

@import 'wk-lib/utilities/exports';

/// Breadcrumb text color
///
/// @type Color
$wk-breadcrumb-color: wk-primary-color(wkGray, tint2) !default;

/// Breadcrumb divider content
///
/// @type String
$wk-breadcrumb-divider: '»' !default;

/// Breadcrumb divider element color
///
/// @type Color
$wk-breadcrumb-divider-color: $wk-breadcrumb-color !default;

/// Breadcrumb bar background color
///
/// @type Color
$wk-breadcrumb-bar-background-color: wk-primary-color(wkGray, tint6) !default;

@include wk-exports('breadcrumbs') {

    %scss-deps-breadcrumbs {
        components: wk-component-dependencies(
            product-name
        );
        utils: wk-utils-dependencies(
            function wk-rem,
            function wk-primary-color,
            mixin container,
            mixin gutter,
            mixin wk-page-content,
            mixin wk-target-icons,
            variable wk-target-tablet-desktop,
            variable wk-font-weight,
            variable wk-headings-font-stack
        );
    }

    .wk-breadcrumb-bar {
        background-color: $wk-breadcrumb-bar-background-color;
        margin-bottom: gutter();
        position: relative;
        z-index: 1;

        .wk-breadcrumb-container {
            @include container;

            background-color: $wk-breadcrumb-bar-background-color;
        }
    }

    .wk-breadcrumb {
        @include wk-hidden;
        @include wk-page-content(false);

        list-style-type: none;
        margin: 0;
        padding-bottom: wk-rem(8px);
        padding-top: wk-rem(8px);

        @include breakpoint($wk-target-tablet-desktop) {
            @include wk-shown;
            float: left;
        }

        > li {
            color: $wk-breadcrumb-color;
            display: inline-block;
            font-family: $wk-headings-font-stack;
            font-size: map-get($wk-font-scale, small);
            font-weight: map-get($wk-font-weight, light);

            @include wk-target-icons {
                margin-left: 0;
                margin-right: wk-rem(7px);
            }

            &:first-child {
                font-weight: map-get($wk-font-weight, medium);
            }

            &:not(:first-child):before {
                color: $wk-breadcrumb-divider-color;
                content: $wk-breadcrumb-divider;
                margin-right: wk-rem(4px);
            }
        }
    }
}
