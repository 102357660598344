////
/// @group wk.css.lib.mixins.labels
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.9.0
/// @access public
////

/// The background color of the label
///
/// @type Color
$wk-label-default-background: wk-primary-color(wkGray) !default;

/// The text color of the label
///
/// @type Color
$wk-label-default-color: wk-primary-color(wkWhite) !default;

/// The text color of the label
///
/// @type Unit
$wk-label-border-radius: wk-rem(2px) !default;

/// Creates a Label
///
/// @param {String} $wk-label-background-color - Background color of label.
/// @param {String} $wk-label-text-color - Color of text within an label.
///
/// @example scss - Usage
/// .wk-label-black {
///   @include wk-label(#000, #fff);
/// }
///
/// @example css - Output
/// .wk-label-black {
///   background-color: #000;
///   border-radius: .0125rem;
///   color: #fff;
///   display: inline-block;
///   font-size: .8em;
///   font-weight: 700;
///   padding: 0.325rem;
///   vertical-align: middle;
///   white-space: nowrap;
/// }

@mixin wk-label($wk-label-background-color: $wk-label-default-background, $wk-label-text-color: $wk-label-default-color) {
    background-color: $wk-label-background-color;
    border-radius: $wk-label-border-radius;
    color: $wk-label-text-color;
    display: inline-block;
    font-size: wk-rem(11px);
    font-weight: map-get($wk-font-weight, bold);
    padding: wk-rem(5px);
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
}
