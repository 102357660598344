////
/// @group wk.css.components.popover
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.11.0
/// @access public
////

/// Popover border color
///
/// @type Color
$wk-popover-border-color: wk-primary-color(wkGray, tint5) !default;

/// Popover border style
///
/// @type List
$wk-popover-border: 1px solid $wk-popover-border-color;

/// Popover background color
///
/// @type Color
$wk-popover-background-color: wk-primary-color(wkWhite) !default;

/// Popover box shadow color
///
/// @type Color
$wk-popover-box-shadow-color: rgba(99, 99, 99, .3) !default;

/// Popover box-shadow
///
/// @type List
$wk-popover-box-shadow: 4px 5px 7px -3px $wk-popover-box-shadow-color !default;

/// Popover inner padding
///
/// @type Unit
$wk-popover-body-padding: 10px !default;

/// User popover max-width
///
/// @type Unit
$wk-user-popover-max-width: 300px !default;

/// User popover section even background
///
/// @type Color
$wk-user-popover-even-background: wk-primary-color(wkGray, tint6) !default;

@import 'wk-lib/utilities/utilities';

@include wk-exports('popover') {
    %scss-deps-pagination {
        components: wk-component-dependencies(layout);
        utils: wk-utils-dependencies(
            function wk-primary-color,
            mixin wk-arrow
        );
    }

    .wk-popover {
        background: $wk-popover-background-color;
        border: $wk-popover-border;
        box-shadow: $wk-popover-box-shadow;
        position: absolute;

        .wk-popover-body {
            padding: $wk-popover-body-padding;
        }

        &.wk-popover-position-top {
            @include wk-arrow(top, center, 8px, 0, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-top-left {
            @include wk-arrow(top, left, 8px, 20px, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-top-right {
            @include wk-arrow(top, right, 8px, 20px, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-bottom {
            @include wk-arrow(bottom, center, 8px, 0, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-bottom-left {
            @include wk-arrow(bottom, left, 8px, 20px, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-bottom-right {
            @include wk-arrow(bottom, right, 8px, 20px, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-left {
            @include wk-arrow(left, center, 8px, 0, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-left-top {
            @include wk-arrow(left, top, 8px, 5px, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-left-bottom {
            @include wk-arrow(left, bottom, 8px, 5px, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-right {
            @include wk-arrow(right, center, 8px, 0, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-right-top {
            @include wk-arrow(right, top, 8px, 5px, $wk-popover-background-color, $wk-popover-border-color);
        }

        &.wk-popover-position-right-bottom {
            @include wk-arrow(right, bottom, 8px, 5px, $wk-popover-background-color, $wk-popover-border-color);
        }

    }

    .wk-user-popover {
        max-width: $wk-user-popover-max-width;

        .wk-user-popover-section {
            border-bottom: 1px solid $wk-popover-border-color;
            padding: $wk-popover-body-padding;

            &:nth-child(even) {
                background: $wk-user-popover-even-background;
            }

            &:last-child {
                border-bottom: 0;
            }

            .wk-module-heading {
                margin: 0;
                margin-bottom: 10px;
            }
        }
    }
}
